import to_do_item from "../../components/default/todo_item/to_do_item.js";
import to_do_list from "../../components/default/todo_list/to_do_list.js";


(() => {
  let __script = {};
  let __serverMethods = [{"name": "_refresh", "endpoint": ["/tetra/demo/default/counter/_refresh"]}, {"name": "increment", "endpoint": ["/tetra/demo/default/counter/increment"]}, {"name": "_upload_temp_file", "endpoint": "/tetra/demo/default/counter/_upload_temp_file"}];
  let __serverProperties = ["key"];
  let __componentName = 'demo__default__counter';
  window.document.addEventListener('alpine:init', () => {
    Tetra.makeAlpineComponent(
      __componentName,
      __script,
      __serverMethods,
      __serverProperties,
    )
  })
})();
(() => {
  let __script = {};
  let __serverMethods = [{"name": "_refresh", "endpoint": ["/tetra/demo/default/reactive_search/_refresh"]}, {"name": "watch_query", "watch": ["query"], "throttle": 200, "throttle_trailing": true, "throttle_leading": null, "endpoint": ["/tetra/demo/default/reactive_search/watch_query"]}, {"name": "_upload_temp_file", "endpoint": "/tetra/demo/default/reactive_search/_upload_temp_file"}];
  let __serverProperties = ["key", "query"];
  let __componentName = 'demo__default__reactive_search';
  window.document.addEventListener('alpine:init', () => {
    Tetra.makeAlpineComponent(
      __componentName,
      __script,
      __serverMethods,
      __serverProperties,
    )
  })
})();
(() => {
  let __script = to_do_item;
  let __serverMethods = [{"name": "_refresh", "endpoint": ["/tetra/demo/default/to_do_item/_refresh"]}, {"name": "save", "watch": ["title", "done"], "debounce": 200, "debounce_immediate": null, "endpoint": ["/tetra/demo/default/to_do_item/save"]}, {"name": "delete_item", "endpoint": ["/tetra/demo/default/to_do_item/delete_item"]}, {"name": "_upload_temp_file", "endpoint": "/tetra/demo/default/to_do_item/_upload_temp_file"}];
  let __serverProperties = ["key", "title", "done"];
  let __componentName = 'demo__default__to_do_item';
  window.document.addEventListener('alpine:init', () => {
    Tetra.makeAlpineComponent(
      __componentName,
      __script,
      __serverMethods,
      __serverProperties,
    )
  })
})();
(() => {
  let __script = to_do_list;
  let __serverMethods = [{"name": "_refresh", "endpoint": ["/tetra/demo/default/to_do_list/_refresh"]}, {"name": "add_todo", "endpoint": ["/tetra/demo/default/to_do_list/add_todo"]}, {"name": "_upload_temp_file", "endpoint": "/tetra/demo/default/to_do_list/_upload_temp_file"}];
  let __serverProperties = ["key", "title"];
  let __componentName = 'demo__default__to_do_list';
  window.document.addEventListener('alpine:init', () => {
    Tetra.makeAlpineComponent(
      __componentName,
      __script,
      __serverMethods,
      __serverProperties,
    )
  })
})();